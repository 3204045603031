import React from "react"
import { useParams } from "react-router";
import { useFindAccountingServiceSummaryQuery } from "../../../../../services/accountService/account-api";
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { Grid, GridColumn as Column, GridDataStateChangeEvent, GridRowProps } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import { formatNumber } from "@progress/kendo-intl";

function AccountingServiceSummaryChart(){
    const {center, month, year} = useParams();
    const {data} = useFindAccountingServiceSummaryQuery({center: center!, period: Number(month!), year: Number(year!)});

    return <React.Fragment>
        <Card sx={{width:'850px', position:'relative', overflow: 'visible', padding:'0px'}}>
            <CardHeader title="Accounting Service Summary" sx={{backgroundColor:'#62BCBD', color:'white', height:'40px', textAlign:'center'}}/>
            <CardContent sx={{padding:'0px'}}>
                <Grid data={data}>
                    <Column title='Service' field='service' width={'250px'}/>
                    <Column title='Booked Revenue' field='bookedRevenue' format='{0:c}' width={'150px'}/>
                    <Column title='Expected Revenue' field='expectedRevenue' format='{0:c}' width={'150px'}/>
                    <Column title='Delta' field='delta' format='{0:c}' width={'150px'}/>
                </Grid>
            </CardContent>
        </Card>
    </React.Fragment>
}
export {AccountingServiceSummaryChart}